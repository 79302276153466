import * as React from "react"
import { graphql } from "gatsby"
import { linkResolver } from '../../utils/link_resolver'
import Seo from '../../components/seo'
import Footer from '../../components/footer'
import Header from '../../components/header'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const AboutPage = ({data}) => {
  const entry = data.prismicAboutPage

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <div className="w-full py-8 md:py-36 relative overflow-hidden">
        <div className="absolute inset-y-0 -right-60 lg:inset-0">
          <GatsbyImage className="w-full h-full object-cover block" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
        </div>
        <div className="absolute inset-0">
          <div className="flex h-full">
            <div className="w-6/12">
              &nbsp;  
            </div>
            <div className="w-6/12 h-full bg-gradient-to-l from-black"></div>
          </div>
        </div>
        <div className="absolute h-96 w-full bottom-0 overflow-hidden">
          <div className="absolute -right-8 md:-right-16 bottom-16 opacity-40">
            <img className="w-36 h-24 md:w-72 md:h-60 -rotate-45" src="/img/paw_print.svg" alt="" />  
          </div>
        </div>
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between relative">
            <div className="w-full md:w-6/12">
              &nbsp;
            </div>
            <div className="w-full md:w-6/12">
              <div className="font-poppins leading-snug text-2xl md:text-3xl text-white font-semibold">
                <h2 className="mb-4 font-bold leading-tight"><span className="bg-orange px-3 py-1 inline-block">{entry.data.tag}</span></h2>
                <p className="mt-48 md:mt-0 ml-0 md:ml-3 text-center md:text-left leading-tight">{entry.data.heading}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-diamond-10 pt-16 pb-8">
        <div className="w-9/12 md:w-7/12 mx-auto max-w-screen-xl text-center">
          <div className="font-poppins leading-snug text-2xl md:text-3xl">
            <h2 className="mb-3"><span className="bg-white block md:inline-block px-6 py-1 font-bold text-diamond">{entry.data.copy_tag}</span></h2>
            <h2><span className="ml-0 md:ml-6 text-orange font-semibold">{entry.data.copy_heading}</span></h2>
          </div>
        </div>
      </div>
      <div className="w-full bg-diamond-10 pb-16 rounded-br-[133px]">
        <div className="w-10/12 md:w-7/12 mx-auto max-w-screen-xl">
          <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
            <PrismicRichText linkResolver={linkResolver} field={entry.data.copy_text.richText} />
          </div>
        </div>
      </div>
      <div className="w-full bg-white pt-16 pb-8">
        <div className="w-8/12 mx-auto max-w-screen-xl font-poppins text-center text-diamond text-2xl font-semibold leading-snug">
          <h2>{entry.data.support_local_heading}</h2>
        </div>
      </div>
      <div className="w-full bg-white pb-16">
        <div className="w-8/12 mx-auto max-w-screen-xl">
          <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
            <PrismicRichText linkResolver={linkResolver} field={entry.data.support_local_text.richText} />
          </div>
        </div>
      </div>
      <div className="w-full bg-white">
        <div className="flex-none md:flex">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full object-cover block py-12 md:py-0" image={entry.data.bio_image.gatsbyImageData} alt={entry.data.bio_image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 bg-orange rounded-tr-[133px] flex justify-center items-center">
            <div className="pt-8 pb-16 pl-16 pr-24 font-poppins max-w-none prose prose-h2:text-white prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium prose-p:text-white leading-snug">
              <PrismicRichText linkResolver={linkResolver} field={entry.data.bio_text.richText} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white">
        <div className="w-full bg-diamond rounded-bl-[133px]">
          <div className="w-full pt-16 pb-12 lg:pb-6 text-center">
            <div className="w-10/12 sm:w-10/12 mx-auto max-w-screen-xl">
              <h2 className="font-poppins text-white font-semibold text-2xl leading-tight">
                {entry.data.guarantee_heading}
              </h2>
            </div>
          </div>
          <div className="w-full pb-16">
            <div className="w-8/12 sm:w-10/12 mx-auto max-w-screen-xl">
              <div className="sm:text-left flex-none sm:flex justify-between items-center gap-6 md:gap-0 lg:gap-12 xl:gap-6">
                <div className="flex justify-center w-full sm:w-4/12 lg:w-2/12 mb-12 sm:mb-0">
                  <figure className="flex items-center justify-center w-36 h-36 lg:w-40 lg:h-40 bg-white p-3 rounded-full">
                    <GatsbyImage className="w-36 h-36 lg:w-40 lg:h-40 inline-block mx-auto bg-white p-3 rounded-full" image={entry.data.guarantee_image.gatsbyImageData} alt={entry.data.guarantee_image.alt ?? ''} /> 
                  </figure>
                </div>  
                <div className="w-full sm:w-8/12 lg:w-10/12">
                  <div className="font-poppins max-w-none prose prose-a:text-white prose-a:hover:text-white prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-white leading-snug">
                    <PrismicRichText linkResolver={linkResolver} field={entry.data.guarantee_text.richText} />
                  </div>
                </div>   
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white pt-16 pb-12">
        <div className="w-8/12 mx-auto max-w-screen-xl text-center font-poppins text-orange text-2xl font-semibold leading-tight">
          <h2>{entry.data.friends_heading}</h2>
        </div>
      </div>
      <div className="w-full pb-16 bg-white relative">
        <div className="absolute z-10 right-12 bottom-24 opacity-10">
          <img className="w-60 h-48 -rotate-45" src="/img/paw_print.svg" alt="" />  
        </div>
        <div className="w-10/12 mx-auto max-w-screen-xl relative">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
          {entry.data.friends.map((entry, i) => (
            <div key={i}>
              <figure>
                <GatsbyImage className="aspect-w-4 aspect-h-3 w-full h-full object-cover block rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px]" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} />
              </figure>
              <figcaption className="font-poppins max-w-none prose prose-h2:text-xl prose-h2:text-orange prose-h2:mb-3 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
                <h2>{entry.heading1}</h2>
                <p>{entry.text}</p>
              </figcaption>
            </div>
          ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicAboutPage {
    data {
      meta_description
      page_title
      tag
      heading
      image {
        alt
        gatsbyImageData
      }
      copy_tag
      copy_heading
      copy_text {
        richText
      }
      support_local_heading
      support_local_text {
        richText
      }
      guarantee_heading
      guarantee_image {
        alt
        gatsbyImageData
      }
      guarantee_text {
        richText
      }
      bio_text {
        richText
      }
      bio_image {
        alt
        gatsbyImageData
      }
      friends_heading
      friends {
        heading1
        image1 {
          alt
          gatsbyImageData
        }
        text
      }
    }
  }
}
`

export default AboutPage
